@import "./fonts";
@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "~animate.css/animate.min.css";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

body {
  background: $gray-100;

  .container {
    background: $white;
  }

  $btn-rogala-border-radius-lg: 1.875rem;
  $btn-rogala-padding-x-lg: calc(5 * #{$btn-padding-x-lg});

  $btn-rogala-border-radius-sm: 1.875rem;
  $btn-rogala-padding-x-sm: calc(2 * #{$btn-padding-x-sm});

  .btn-rogala {
    border-radius: 1.875rem;
    padding: $btn-padding-y calc(2.5 * #{$btn-padding-x});

    @include media-breakpoint-down(lg) {
      font-size: 1.375rem;
      line-height: 2;
    }

    &.btn-lg {
      @include button-size($btn-padding-y-lg, $btn-rogala-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-rogala-border-radius-lg);
    }

    &.btn-sm {
      @include button-size($btn-padding-y-sm, $btn-rogala-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-rogala-border-radius-sm);
    }
  }
  .btn-rogala-blue {
    border-radius: 1.875rem;
    padding: $btn-padding-y calc(2.5 * #{$btn-padding-x});

    &.btn-lg {
      @include button-size($btn-padding-y-lg, $btn-rogala-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-rogala-border-radius-lg);
    }

    &.btn-sm {
      @include button-size($btn-padding-y-sm, $btn-rogala-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-rogala-border-radius-sm);
    }
  }

  .font-corbel {
    font-family: Corbel;
  }

  input,
  textarea,
  select {
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .form-control {
    transition: none;
    border-color: $white;
    &:focus {
      border-color: $white;
      box-shadow: none;
    }
  }

  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      &:active,
      &:hover,
      &:focus {
        background-color: $value !important;
        border-color: $value !important;
        box-shadow: none !important;
      }

      &:hover {
        animation: pulse 1s infinite;
      }
    }
  }

  .backToTop {
    color: $gray-500;
    height: 50px;
    position: fixed;
    bottom: 20px;
    width: 50px;
    cursor: pointer;
    opacity: 0;
    right: -50px;
    z-index: 1000;
    outline: none;
    transition: all 0.5s ease-in-out;
    transition-property: opacity, right;

    &.backToTopTransition {
      opacity: 1;
      right: 20px;
    }
  }
}