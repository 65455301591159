@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

$border-radius: 1rem;
@mixin header() {
  @include font-size(20px);
}

@mixin body() {
  @include font-size(15px);
}

@mixin send-container() {
  height: 50px;
  width: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  &::after {
    content: 'Sending';
    font-weight: 700;
    position: absolute;
    bottom: -50px;
    left: -10px;
  }
}

@mixin send-border {
  position: absolute;
}

@mixin send-line {
  height: 4px;
  position: absolute;
  width: 0%;
  left: 25%;
}

.container {
  .header {
    @include header();
    background: #1F5D9B;
    color: $white;
  }

  .body {
    $color: $black;

    @include body();
    background: #EEEEEE;

    .sendContainer {
      @include send-container();

      &::after {
        color: $color;
      }

      .border {
        @include send-border();
        background: $color;
      }

      .line {
        @include send-line();
        background: $color;
      }
    }
  }
}

.containerBlue {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

  .headerBlue {
    @include header();
    background: $white;
    color: $black;
  }

  .bodyBlue {
    $color: $white;

    @include body();
    background: #1F5D9B;
    color: $color;

    .sendContainerBlue {
      @include send-container();

      &::after {
        color: $color;
      }

      .borderBlue {
        @include send-border();
        background: $color;
      }

      .lineBlue {
        @include send-line();
        background: $color;
      }
    }
  }
}

.form {
  min-height: 505px;
}

.radiusTop {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.radiusBottom {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.box {
  position: relative;
  height: 50px;
  width: 40px;
  animation: box 5s infinite linear;
}

.borderOne {
  height: 4px;
  top: 0;
  left: 0;
  animation: border-one 5s infinite linear;
}

.borderTwo {
  top: 0;
  right: 0;
  height: 100%;
  width: 4px;
  animation: border-two 5s infinite linear;
}

.borderThree {
  bottom: 0;
  right: 0;
  height: 4px;
  width: 100%;
  animation: border-three 5s infinite linear;
}

.borderFour {
  bottom: 0;
  left: 0;
  height: 100%;
  width: 4px;
  animation: border-four 5s infinite linear;
}

.lineOne {
  top: 25%;
  width: 0%;
  animation: line-one 5s infinite linear;
}

.lineTwo {
  top: 45%;
  animation: line-two 5s infinite linear;
}

.lineThree {
  top: 65%;
  animation: line-three 5s infinite linear;
}


.successCheckmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .checkIcon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: transparent;
      transform: rotate(-45deg);
    }

    .iconLine {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.lineTip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.lineLong {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .iconCircle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .iconFix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: transparent;
    }
  }
}

.failedCheckmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .failedCheckIcon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid rgb(175, 76, 76);

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: rgba(255, 255, 255, 0);
      transform: rotate(-45deg);
    }

    .failedIconLine {
      height: 5px;
      background-color: rgb(175, 76, 76);
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.failedLineLongInvert {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(45deg);
        animation: failed-icon-line-long-invert 0.75s;
      }

      &.failedLineLong {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(-45deg);
        animation: failed-icon-line-long 0.85s;
      }
    }

    .failedIconCircle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(175, 76, 76, 0.5);
    }

    .failedIconFix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

@keyframes border-one {
  0%   {width:0;}
  10%  {width:100%;}
  100% {width:100%;}
}

@keyframes border-two {
  0%   {height:0;}
  10%  {height:0%;}
  20%  {height:100%;}
  100% {height:100%;}
}

@keyframes border-three {
  0%   {width:0;}
  20%  {width:0%;}
  30%  {width:100%;}
  100% {width:100%;}
}

@keyframes border-four {
  0%   {height:0;}
  30%  {height:0%;}
  40%  {height:100%;}
  100% {height:100%;}
}

@keyframes line-one {
  0%   {left:25%;width:0;}
  40%  {left:25%;width:0%;}
  43%  {left:25%;width:50%;}
  52%  {left:25%;width:50%;}
  54%  {left:25%;width:0% }
  55%  {right:25%;left:auto;}
  63%  {width:10%;right:25%;left:auto;}
  100% {width:10%;right:25%;left:auto;}
}

@keyframes line-two {
  0%   {width:0;}
  42%  {width:0%;}
  45%  {width:50%;}
  53%  {width:50%;}
  54%  {width:0% }
  60%  {width:50%}
  100% {width:50%;}
}

@keyframes line-three {
  0%   {width:0;}
  45%  {width:0%;}
  48%  {width:50%;}
  51%  {width:50%;}
  52%  {width:0% }
  100% {width:0%;}
}

@keyframes box {
  0% {opacity:1;margin-left:0px;height:50px;width:40px;}
  55% {margin-left:0px;height:50px;width:40px;}
  60% {margin-left:0px;height:35px;width:50px;}
  74% {msthin-left:0;}
  80% {margin-left:-50px;opacity:1;}
  90% {height:35px;width:50px;margin-left:50px;opacity:0;}
  100% {opacity:0;}
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes failed-icon-line-long {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-45deg);
  }
}
@keyframes failed-icon-line-long-invert {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}