@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.category {
  @include font-size(13px);
  border-radius: 30px !important;
  padding: $btn-padding-y calc(1.5 * #{$btn-padding-x}) !important;
}

.title {
  @include font-size(18px);

  a {
    color: $black;
  }
}

.image {
  max-width: 90%;
  max-height: 350px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;

  &:hover,
  &:focus {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.content {
  @include font-size(12px)
}