@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/vendor/rfs";

@mixin pageNonNumberLink() {
  .link {
    @include font-size(16px)
  }
}

.banner {
  height: 394px;
  background-repeat: no-repeat;
  background-position: top center;

  @include media-breakpoint-down(sm) {
    background-position: -600px 0px;
  }
}

.loading {
  height: 250px;
}

.tagButton {
  font-size: $btn-font-size !important;
  line-height: $btn-line-height !important;
}

.pagination {
  display: flex;
  list-style: none;

  .previous {
    @include pageNonNumberLink()
  }

  .page {
    padding: calc(0.5 * #{$pagination-padding-y}) calc(0.5 * #{$pagination-padding-x});
  }

  .breakMe {
    @include pageNonNumberLink()
  }

  .next {
    @include pageNonNumberLink()
  }

  .active {
    .link {
      color: #5E5E5E;
      font-weight: bold;
      @include font-size(15px)
    }
  }

  .disable {
    display: none;
  }

  .link {
    color: #B5B5B5;
    @include font-size(13px);
  }
}