@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$enable-responsive-font-sizes: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

$rogala: #EC7A17;
$light-rogala: #FAAE17;
$lighten-rogala: #FFC637;
$rogala-blue: #0E9AFF;

$theme-colors: (
  "rogala": $rogala,
  "light-rogala": $light-rogala,
  "lighten-rogala": $lighten-rogala,
  "rogala-blue": $rogala-blue,
);

$spacers: (
  6: ($spacer * 4.5),
  7: ($spacer * 6),
  8: ($spacer * 7.5),
  9: ($spacer * 9),
  10: ($spacer * 10.5)
);

$sizes: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%
)
