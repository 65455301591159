.container {
  position: relative;
  height: 45vh;
  width: 100%;

  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 920px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    .notfound404 {
      position: absolute;
      height: 100px;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: -1;

      h1 {
        font-family: maven pro, sans-serif;
        color: #ececec;
        font-weight: 900;
        font-size: 276px;
        margin: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
      }
    }

    h2 {
      font-family: maven pro, sans-serif;
      font-size: 46px;
      color: #000;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0
    }

    p {
      font-family: maven pro, sans-serif;
      font-size: 16px;
      color: #000;
      font-weight: 400;
      text-transform: uppercase;
      margin-top: 15px
    }
  }

  @media only screen and (max-width: 480px) {
    .notfound .notfound404 h1 {
      font-size: 162px
    }

    .notfound h2 {
      font-size: 26px
    }
  }
}
