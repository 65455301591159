@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.bannerWrapper {
  position: relative;
}

.textWrapper {
  position: absolute;
  top: 10%;
}

.textContainer {
  background: transparent !important;
  @include font-size(24px);
  line-height: 1.25;
}

.buttonMeetUp {
  font-size: 1.35rem;
}

.aboutMe {
  @include font-size(20px);
  line-height: 1.25;
  font-weight: 550;
}

.bannerMobile {
  width: 1098px;
  height: 393px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.textWithScroll {
  $light-rogala: #FAAE17;
  @include font-size(20px);
  line-height: 1.25;

  .scroll {
    position: relative;
    border-left: 3px solid $light-rogala;

    .square {
      position: absolute;
      top: 40px;
      left: -6px;
      width: 9px;
      height: 9px;
      background: $light-rogala;
    }
  }
}

.aboutMeImage {
  height: 464px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-down(xs) {
    background-position: -330px 0px;
    background-size: cover;
  }
}
