@import "mixins";

@include font-face(Corbel, "font/Corbel", 400, normal, ttf);
@include font-face(Corbel, "font/Corbel-Italic", 400, italic, ttf);

@include font-face(Corbel, "font/Corbel-Bold", 700, normal, ttf);
@include font-face(Corbel, "font/Corbel-BoldItalic", 700, italic, ttf);

@include font-face(Nunito, "font/Nunito-Light", 300, normal, ttf);

@include font-face(Nunito, "font/Nunito-Regular", 400, normal, ttf);

@include font-face(Nunito, "font/Nunito-SemiBold", 600, normal, ttf);

@include font-face(Nunito, "font/Nunito-Bold", 700, normal, ttf);