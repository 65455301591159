@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.link {
  color: initial;
  text-decoration: underline;
}

.linkBlue {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $white;
  }
}