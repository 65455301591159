@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.banner {
  background: #EDEDED;
  height: 391px;
  @include font-size(15px);
}

.bannerMobile {
  height: 391px;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #EDEDED;
  background-size: cover;
}

.title {
  font-weight: bold;
  @include font-size(22px);
}

.offerCaption {
  @include font-size(18px);
}

.offerImage {
  height: 667px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  @include media-breakpoint-down(xs) {
    height: 570px;
  }
}

.offer {
  @include font-size(16px);
}

.workshop {
  @include font-size(18px);
}

.workshopTime {
  @include font-size(40px);
  color: #FFD260;
  font-weight: bold;
}
