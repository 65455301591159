@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.title {
  font-weight: 700;
}
.contact {
  @include font-size(16px);

  .link {
    color: $black;
  }
}

.socialIcon {
  $color: #636363;
  $facebook: #4267B2;
  $linkedin: #2867B2;
  $youtube: #FF0000;

  color: $color;
  a {
    color: $color;

    &.facebook {
      &:hover {
        color: $facebook;
      }
    }

    &.linkedin {
      &:hover {
        color: $linkedin;
      }
    }

    &.youtube {
      &:hover {
        color: $youtube;
      }
    }
  }
}