@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.testimonials {
    color: $black;
    height: 90%;
    width: 100%;
    padding: 3.125rem 0px;

    .wrapper {
        padding: 3.125rem 3.125rem 0px;
        height: 100%;
        background: #f6f6f6;
        border-radius: 0.5rem;

        .icons {
            font-size: 5rem;
            margin-top: -5.625rem;

            .quote {
                color: #EC7A17
            }
        }
        .carousel {
            height: 100%;

            .carousel-inner {
                height: 90%;

                .carousel-item {
                    width: 100%;
                    height: 750px;
                    background: transparent;
                    @include media-breakpoint-up(sm) {
                        height: 450px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 300px;
                    }

                    .content {
                        padding-bottom: 1rem;
                        h1, h2, h3, h4, h5, h6 {
                            margin-bottom: 2rem;
                        }
                    }
                }

            }

            .carousel-indicators {
                bottom: -2rem;
                li {
                    height: 1rem;
                    width: 1rem;
                    background-color: #EC7A17;
                    border-radius: 50%;
                    opacity: 0.3;

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
