@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.number {
  @include font-size(64px);
  text-align: right;
  color: rgba(0, 0, 0, 0.1);
  font-family: Asar;
  line-height: 1;
}

.title {
  @include font-size(15px);
  color: $black;
  font-weight: bold;
}

.list {
  @include font-size(13px);

  .itemText {
    padding: 3px 0px;

    .itemNumber {
      padding: 2px 7px;
      //border: 0.6px solid #7B7B7B;;
      margin-right: 1rem;
    }
  }
}