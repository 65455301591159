@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/vendor/rfs";

.title {
  @include font-size(18px);
  font-weight: 700;
}

.line {
  height: 3px;
  width: 75px;

  @include media-breakpoint-up(xl) {
    height: 6px;
    width: 44px;
  }
}

.offer {
  @include font-size(13px);
}
