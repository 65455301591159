@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/vendor/rfs";

.navbar {
  padding: 1.5rem 0rem;

  .navbar-brand {
    img {
      max-height: 60px;

      @include media-breakpoint-down(xs) {
        max-height: 40px;
      }
    }
  }

  .navbar-collapse {
    .navbar-nav,
    .navbar-text {
      .nav-link {
        @include font-size(14px);
        color: $black;
        padding-right: $navbar-nav-link-padding-x;
        padding-left: $navbar-nav-link-padding-x;
      }
    }

    .navbar-text {
      padding-top: calc(#{$nav-link-padding-y}/2);
      padding-bottom: calc(#{$nav-link-padding-y}/2);
    }

    .nav-item {
      padding-bottom: calc(#{$nav-link-padding-y}/2);
      margin-bottom: calc(#{$nav-link-padding-y}/2);
      margin-top: calc(#{$nav-link-padding-y}/2);
      border-bottom: 1px solid white;

      &.active {
        border-bottom-color: $black;
      }
    }

    .nav-link {
      padding: 0rem;
    }

    .navbar-text {
      font-weight: bold;
    }
  }
}
