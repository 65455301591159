@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.content {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
}

.body {
  padding: 0;
}

.link {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $white;
  }
}