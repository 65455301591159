@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/vendor/rfs";

.numbers {
  @include font-size(13px);

  .image {
    padding-left: $grid-gutter-width;
  }

  .value {
    @include font-size(32px);
    padding-left: 1rem;

    @include media-breakpoint-up(md) {
      @include font-size(48px);
    }
  }

  .unite {
    @include font-size(16px);
    padding: 0.125rem;

    @include media-breakpoint-up(md) {
      @include font-size(18px);
    }
  }
}